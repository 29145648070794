<template>
  <swiper-container
    slides-per-view="1"
    speed="500"
    loop="true"
    navigation="true"
    :autoplay="{ delay: 15000 }"
  >
    <swiper-slide
      class="relative flex h-full w-full flex-col-reverse bg-white md:flex-col"
    >
      <div
        class="relative top-[1%] z-20 w-full px-[5%] md:absolute sm:z-0 md:left-0 md:top-[1%] md:w-max md:translate-x-0 md:px-[9%] xl:top-[1%]"
      >
        <div class="rounded-md bg-white/60 py-[10px] xs:px-[20px]">
          <h2
            class="text-[23px] font-medium leading-[34px] md:text-[14px] lg:text-[24px] xl:text-[40px] xl:leading-[48px]"
          >
            Успейте до подорожания!
          </h2>
          <div
            class="mt-[12px] lg:gap-[0_20px] xl:mt-[10px] 2xl:block"
          >
            <p
              class="text-[17px] font-normal xs:text-[20px] md:text-[14px] lg:text-[22px] xl:text-[30px]"
            >
              ВЫГОДА ДО&nbsp;<nobr><b>1&nbsp;000&nbsp;000&nbsp;руб</b></nobr>.
            </p>
            <p
              class="text-[17px] font-normal xs:text-[20px] md:text-[14px] lg:text-[22px] xl:text-[30px]"
            >
              <b>РАССРОЧКА 0%</b>
            </p> 
            <p
              class="text-[17px] font-normal xs:text-[20px] md:text-[14px] lg:text-[22px] xl:text-[30px]"
            >
              <b>ПОДАРКИ</b>
            </p>            
            <p
              class="text-[17px] font-normal xs:text-[20px] md:text-[14px] lg:text-[22px] xl:text-[30px]"
            >
              ДЕШЕВЛЕ УЖЕ НЕ БУДЕТ! СПЕШИТЕ!
            </p>
          </div>
        </div>
        <buttons--button-modal-caller
          modal-title="Узнать подробности"
          modal-button="Узнать подробности"
          class="flex cursor-pointer text-white text-xl md:text-[10px] lg:text-xl bg-[#002E5D] md:w-fit uppercase justify-center items-center md:flex md:h-full py-[22px] px-[30px] md:max-lg:py-[10px] w-full mt-[3vw]"
          >
            Узнать подробности
        </buttons--button-modal-caller>
      </div>
      <picture>
        <source
          srcset="@/images/backgrounds/new-year-conditions_1920x650.jpg"
          media="(min-width: 1600px)"
        />
        <source
          srcset="@/images/backgrounds/new-year-conditions_800x600.jpg"
          media="(max-width: 768px)"
        />
        <img
          src="@/images/backgrounds/new-year-conditions_1920x650.jpg"
          alt="Geely на особых условиях"
          class="w-full"
        />
      </picture>
    </swiper-slide>
    <swiper-slide
      class="relative flex h-full w-full flex-col-reverse bg-white md:flex-col"
    >
      <div
        class="relative top-[1%] z-20 w-full px-[5%] md:absolute sm:z-0 md:left-1/2 md:top-[1%] md:-translate-x-1/2 md:px-[9%] lg:left-0 lg:flex lg:h-full lg:w-1/2 lg:translate-x-0 lg:flex-col lg:justify-center lg:px-[6%] xl:flex xl:h-full xl:items-center"
      >
        <div class="rounded-md bg-white/60 py-2 xs:px-5 2xl:py-8">
          <h2
            class="text-2xl font-medium leading-snug md:text-2xl xl:text-4xl xl:leading-tight 2xl:text-5xl"
          >
            Успейте до подорожания!<br>Geely Monjaro
          </h2>
          <div class="mt-3 xl:mt-2">
            <ul class="list-inside list-disc">
              <li class="text-base font-normal xs:text-xl xl:text-2xl 2xl:text-4xl 2xl:leading-tight">
                Выгода <b>до 450&nbsp;000₽</b>
              </li>
              <li class="text-base font-normal xs:text-xl xl:text-2xl 2xl:text-4xl 2xl:leading-tight">
                Рассрочка 0%
              </li>
              <li class="text-[17px] font-normal xs:text-[20px] md:text-[14px] lg:text-[22px] xl:text-[30px]">
                Улучшим любое предложение!
              </li>
            </ul>
          </div>
        </div>
      </div>
      <picture>
        <source
          srcset="@/images/backgrounds/sale_geely_monjaro_1920_650.jpg"
          media="(min-width: 1600px)"
        />
        <source
          srcset="@/images/backgrounds/sale_geely_monjaro_800_600.jpg"
          media="(max-width: 768px)"
        />
        <img
          src="@/images/backgrounds/sale_geely_monjaro_1920_650.jpg"
          alt="ЛИКВИДИРУЕМ СКЛАД Geely Monjaro"
          class="w-full"
        />
      </picture>
    </swiper-slide>
  </swiper-container>
</template>
<style scoped>
swiper-container::part(button-prev) {
  @apply absolute left-5 top-1/2 z-10 h-20 w-10 -translate-y-1/2 cursor-pointer text-primary-blue/60 outline-none duration-300 ease-in-out;
}

swiper-container::part(button-next) {
  @apply absolute right-5 top-1/2 z-10 h-20 w-10 -translate-y-1/2 cursor-pointer text-primary-blue/60 outline-none duration-300 ease-in-out;
}

swiper-container::part(bullet-active) {
  @apply bg-primary-blue;
}
</style>
